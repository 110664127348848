import { Card, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { cacheStore } from 'store/bearStore';
import { UNIT } from 'utils/StringDefinition';
import RowTextInputComponent from '../share-components/RowInput';
import RowSelectComponent from '../share-components/RowSelect';
import RowTextResult from '../share-components/RowTextResult';
import SectionTitle from '../share-components/Tilte';
import image from './4_1.png';
import Tablei165I178 from './Tablei165I178';

const Block0401 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.tool002version100?.[`block004`];

    const cacheData = cacheStore((state) => state.cacheData);
    // Dùng để lưu cache, nó là 1 bộ nhớ đệm, loại bỏ trường hợp khi nhập dữ liệu quá nhanh, dữ liệu sẽ bị mất
    const setCacheData = cacheStore((state) => state.setCacheData);

    const mergedObject = { ...blockData };

    for (const key in cacheData) {
        if (cacheData.hasOwnProperty(key)) {
            mergedObject[key] = cacheData[key];
        }
    }

    useEffect(() => {
        setCacheData(blockData);
    }, [blockData]);

    const listF166 = [
        { id: 1, value: 2 },
        { id: 2, value: 3 },
        { id: 3, value: 4 },
        { id: 4, value: 5 },
        { id: 5, value: 6 },
        { id: 6, value: 7 },
        { id: 7, value: 8 },
        { id: 8, value: 9 },
        { id: 9, value: 10 },
        { id: 10, value: 11 },
        { id: 11, value: 12 },
        { id: 12, value: 13 },
    ];

    const endOfList = blockData?.f166?.value || 13;
    const listF167 = endOfList && listF166.filter((item) => item.value >= 2 && item.value <= endOfList);

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <div className="h-full w-full relative overflow-hidden flex items-start justify-center xl:flex-col 2xl:flex-row pt-3">
                    <article className="h-full w-full relative overflow-hidden flex items-start justify-center">
                        <img src={image} alt="Block0401_1" />
                    </article>
                    <Typography className="absolute w-full flex top-[1.5rem] left-[-15.15rem] text-sm text-center justify-center">面直角方向</Typography>

                    <Typography className="absolute w-full flex top-[7.5rem] left-[-23.5rem] text-sm text-center justify-center">橋座面</Typography>
                    <Typography className="absolute w-full flex top-[14rem] left-[-24.5rem] text-sm text-center justify-center">上段アンカー</Typography>

                    <Typography className="absolute w-full flex justify-center top-[5.65rem] left-[-12.95rem] text-sm text-center">有効幅 λ</Typography>
                    <Typography className="absolute w-full flex justify-center top-[6.75rem] left-[-12.75rem] text-sm text-center">
                        {isNaN(parseFloat(blockData?.f183?.value)) ? '0' : `${blockData?.f183?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[3.95rem] left-[-14.75rem] text-sm text-center">
                        {isNaN(parseFloat(blockData?.sheet6Data?.aK107?.value)) ? '0' : `${blockData?.sheet6Data?.aK107?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[5.75rem] left-[-16.75rem] text-sm text-center">L1</Typography>
                    <Typography className="absolute w-full flex justify-center top-[6.75rem] left-[-16.75rem] text-sm text-center">
                        {isNaN(parseFloat(blockData?.f106?.value)) ? '0' : `${blockData?.f106?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[11rem] left-[-20.5rem] text-sm text-center -rotate-90">
                        {isNaN(parseFloat(blockData?.f176?.value)) ? 'c = 0' : `c = ${blockData?.f176?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[10.5rem] left-[-16.5rem] text-sm text-center">
                        {isNaN(parseFloat(blockData?.f174?.value)) ? 'R = 0' : `R = ${blockData?.f174?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[11.5rem] left-[-16rem] text-sm text-center">スカーラップ</Typography>
                    <Typography className="absolute w-full flex justify-center top-[14.75rem] left-[-15.5rem] text-sm text-center">フランジとリブの溶接部</Typography>

                    <Typography className="absolute w-full flex justify-center top-[22rem] left-[-17.5rem] text-sm text-center">
                        {isNaN(parseFloat(blockData?.f170?.value)) ? 'L3min = 0' : `L3min = ${blockData?.f170?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[23.5rem] left-[-14.5rem] text-sm text-center">
                        {isNaN(parseFloat(blockData?.f169?.value)) ? 'L3max = 0' : `L3max = ${blockData?.f169?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex top-[1.5rem] left-[0.6rem] text-sm text-center justify-center">面方向</Typography>

                    <Typography className="absolute w-full flex justify-center top-[7.5rem] left-[7.5rem] text-sm text-center -rotate-90">
                        {isNaN(parseFloat(blockData?.f181?.value)) ? 't4 = 0' : `t4 = ${blockData?.f181?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[15rem] left-[2rem] text-sm text-center">L2max</Typography>
                    <Typography className="absolute w-full flex justify-center top-[16.25rem] left-[2rem] text-sm text-center">
                        {isNaN(parseFloat(blockData?.f165?.value)) ? '0' : `${blockData?.f165?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[15rem] left-[-3rem] text-sm text-center">L2</Typography>
                    <Typography className="absolute w-full flex justify-center top-[16.25rem] left-[-3rem] text-sm text-center">
                        {isNaN(parseFloat(blockData?.f185?.value)) ? '0' : `${blockData?.f185?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[13.25rem] left-[-5.75rem] text-sm text-center">
                        {isNaN(parseFloat(blockData?.f186?.value)) ? 'a = 0' : `a = ${blockData?.f186?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[13.25rem] left-[-2.75rem] text-sm text-center">
                        {isNaN(parseFloat(blockData?.f187?.value)) ? 'b = 0' : `b = ${blockData?.f187?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex top-[25rem] left-[0.75rem] text-sm text-center justify-center">ベースとリブの溶接部</Typography>
                    <Typography className="absolute w-full flex top-[23rem] left-[-6.25rem] text-sm text-center justify-center">中位軸</Typography>
                    <Typography className="absolute w-full flex top-[38rem] left-[0.75rem] text-sm text-center justify-center">ストッパー中心</Typography>
                    <Typography className="absolute w-full flex top-[37.5rem] left-[7.75rem] text-sm text-center justify-center">有効RIBの枚数</Typography>

                    <Typography className="absolute w-full flex top-[23rem] left-[1.5rem] text-sm text-center justify-center">
                        {isNaN(parseFloat(blockData?.sheet4Data?.aK16?.value)) ? '0' : `${blockData?.sheet4Data?.aK16?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[31.5rem] left-[-9.0rem] text-sm text-center -rotate-90">
                        {isNaN(parseFloat(blockData?.f169?.value)) ? 'L3max = 0' : `L3max = ${blockData?.f169?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[25.5rem] left-[-9rem] text-sm text-center -rotate-90">
                        {isNaN(parseFloat(blockData?.f163?.value)) ? 't2 = 0' : `t2 = ${blockData?.f163?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[31.5rem] left-[-7.5rem] text-sm text-center -rotate-90">
                        {isNaN(parseFloat(blockData?._F171?.value)) ? '有効長さ L3 = 0' : `有効長さ L3 = ${blockData?._F171?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[33.5rem] left-[9.75rem] text-sm text-center -rotate-90">
                        {isNaN(parseFloat(blockData?.f183?.value)) ? 'λ = 0' : `λ = ${blockData?.f183?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[29.35rem] left-[9.75rem] text-sm text-center -rotate-90">
                        {isNaN(parseFloat(blockData?.f106?.value)) ? 'L1 = 0' : `L1 = ${blockData?.f106?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[36.25rem] left-[-6.25rem] text-sm text-center">
                        {isNaN(parseFloat(blockData?.f168?.value)) ? 't3 = 0' : `t3 = ${blockData?.f168?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[41rem] left-[-4.25rem] text-sm text-center -rotate-90">RIB 1目</Typography>
                    <Typography className="absolute w-full flex justify-center top-[41rem] left-[-1.25rem] text-sm text-center -rotate-90">RIB 2目</Typography>
                    <Typography className="absolute w-full flex justify-center top-[41rem] left-[7.75rem] text-sm text-center -rotate-90">RIB n目</Typography>

                    <Tablei165I178 arrayValue={blockData?.i165I178} />
                </div>

                <SectionTitle title="ベースプレートとリブプレートに関する寸法" />

                <RowTextInputComponent
                    blockNumber="block004"
                    name="f163"
                    description="ベースプレート厚さ"
                    initValue={blockData?.f163?.value}
                    symbol={{ normalText: 't2', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextInputComponent
                    blockNumber="block004"
                    name="f165"
                    description="RIBの内間隔の最大値"
                    initValue={blockData?.f165?.value}
                    symbol={{ normalText: 'L2', subText: 'max', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                {/* <RowTextInputComponent blockNumber="block004" name="f166" description="RIBの枚数" initValue={blockData?.f166?.value} symbol={{ normalText: 'n3', mathSymbol: '=' }} unit={UNIT.sheet} /> */}

                <RowSelectComponent
                    blockNumber="block004"
                    name="f166"
                    description="RIBの枚数"
                    initValue={blockData?.f166?.value}
                    symbol={{ normalText: 'n3', mathSymbol: '=' }}
                    listValue={listF166}
                    unit={UNIT.sheet}
                />

                {/* <RowTextInputComponent
                    blockNumber="block004"
                    name="f167"
                    description="有効RIBの枚数"
                    initValue={blockData?.f167?.value}
                    symbol={{ normalText: "n3'", mathSymbol: '=' }}
                    unit={UNIT.sheet}
                /> */}

                <RowSelectComponent
                    blockNumber="block004"
                    name="f167"
                    description="有効RIBの枚数"
                    initValue={blockData?.f167?.value}
                    symbol={{ normalText: "n3'", mathSymbol: '=' }}
                    listValue={listF167}
                    unit={UNIT.sheet}
                />

                <RowTextInputComponent blockNumber="block004" name="f168" description="RIBの厚さ" initValue={blockData?.f168?.value} symbol={{ normalText: 't3', mathSymbol: '=' }} unit={UNIT.mm} />

                <RowTextInputComponent
                    blockNumber="block004"
                    name="f169"
                    description="RIBの幅 (上側)"
                    initValue={blockData?.f169?.value}
                    symbol={{ normalText: 'L3', subText: 'max', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextInputComponent
                    blockNumber="block004"
                    name="f170"
                    description="RIBの幅 (下側)"
                    initValue={blockData?.f170?.value}
                    symbol={{ normalText: 'L3', subText: 'min', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextResult
                    blockNumber="block004"
                    name="f171"
                    defaultDisable={true}
                    description="RIBの有効幅 (下側)"
                    initValue={blockData?._F171?.value}
                    symbol={{ normalText: 'L3', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 15, paddingBottom: 2 }}>
                    <Typography>※RIBの有効幅は【RIBの厚さの12倍】と【RIB平均幅】の最小値。</Typography>
                </Box>

                <RowTextInputComponent blockNumber="block004" name="f174" description="スカーラップ" initValue={blockData?.f174?.value} symbol={{ normalText: 'R', mathSymbol: '=' }} unit={UNIT.mm} />

                <RowTextInputComponent
                    blockNumber="block004"
                    name="f176"
                    description="へりあき寸法 (上段アンカー～橋座までの距離)"
                    initValue={blockData?.f176?.value}
                    symbol={{ normalText: 'c', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
            </Stack>
        </Card>
    );
};

export default Block0401;
