const serverBaseSSL = `${process.env.REACT_APP_BASE_URL_PROTOCOL_HTTPS}://${process.env.REACT_APP_API_END_POINT}` || 'https://devhozenapi.ttdesignco.com';
const serverDownloadPDF = `${process.env.REACT_APP_BASE_URL_PROTOCOL_HTTPS}://${process.env.REACT_APP_API_PDF_ENDPOINT}` || 'http://devhozen-excel.ttdesignco.com';

const UrlConstant = {
    HOZEN_API_URL: `${serverBaseSSL}/api`,
    HOZEN_DOWNLOAD_SERVER: `${serverDownloadPDF}/api`,
    HOZEN_HOST_URL: `${serverBaseSSL}`,
};

export default UrlConstant;
