import { Box, Card, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import { UNIT } from 'utils/StringDefinition';
import RowTextThreeInput from '../share-components/RowThreeInput';
import { InstructRow } from '../share-components/Tilte';
import image1 from './3-1.png';
import image2 from './3-2.png';

const Block0301 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.version110?.[`block007`];

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <article className="h-full w-full relative overflow-hidden flex flex-col items-center justify-center m-auto">
                    <img src={image1} alt="Block0301_1" height="375" />
                    <img src={image2} alt="Block0301_2" height="375" />

                    {/* //Text image 1 */}
                    <Typography className="absolute w-full flex justify-center top-[1.5rem] left-[-13.75rem] text-sm text-center">
                        {isNaN(blockData?._T97?.value) ? 'L1' : `L1 = ${blockData?._T97?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[19rem] left-[-21.75rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T98?.value) ? 't1' : `t1 = ${blockData?._T98?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[6.75rem] left-[-6.25rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T99?.value) ? 'h1' : `h1 = ${blockData?._T99?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[7.75rem] left-[-21.75rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T100?.value) ? 't2' : `t2 = ${blockData?._T100?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[17.5rem] left-[-14rem] text-sm text-center">
                        {isNaN(blockData?._T97?.value) ? 'L1' : `L1 = ${blockData?._T97?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[1.5rem] left-[-21.5rem] text-sm text-center">補強リブ</Typography>
                    <Typography className="absolute w-full flex justify-center top-[1.5rem] left-[-5.5rem] text-sm text-center">ベースプレート</Typography>
                    <Typography className="absolute w-full flex justify-center top-[10.75rem] left-[-5.5rem] text-sm text-center">トッププレート</Typography>

                    <Typography className="absolute w-full flex justify-center top-[17.5rem] left-[-5.5rem] text-sm text-center">トッププレート</Typography>

                    {/* //Text image 2 */}
                    <Typography className="absolute w-full flex justify-center top-[1.5rem] left-[4.5rem] text-sm text-center">補強リブ</Typography>
                    <Typography className="absolute w-full flex justify-center top-[1.5rem] left-[20.5rem] text-sm text-center">ベースプレート</Typography>
                    <Typography className="absolute w-full flex justify-center top-[9.5rem] left-[20.75rem] text-sm text-center">トッププレート</Typography>

                    <Typography className="absolute w-full flex justify-center top-[1.5rem] left-[12rem] text-sm text-center">
                        {isNaN(blockData?._T101?.value) ? 'L2' : `L2 = ${blockData?._T101?.value}`}
                    </Typography>
                    {/* <Typography className="absolute w-full flex justify-center top-[3.5rem] left-[14rem] text-sm text-center">
                        {isNaN(blockData?._T116?.value) ? 'e' : `e = ${blockData?._T116?.value}`}
                    </Typography> */}

                    <Typography className="absolute w-full flex justify-center top-[7.75rem] left-[3.95rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T100?.value) ? 't2' : `t2 = ${blockData?._T100?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[6.5rem] left-[20rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T111?.value) ? 'h2' : `h2 = ${blockData?._T111?.value}`}
                    </Typography>

                    {/* <div className="absolute w-full flex justify-center top-[16rem] left-[19rem] text-sm text-center"></div> */}
                    <Typography className="absolute w-full flex justify-start top-[16rem] left-[55rem] text-sm text-center">
                        {isNaN(blockData?._T112?.value) ? 'b1' : `b1 = ${blockData?._T112?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-start top-[17.5rem] left-[55rem] text-sm text-center">
                        {isNaN(blockData?._T117?.value) ? 'a1' : `a1 = ${blockData?._T117?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[21.75rem] left-[5rem] text-sm text-center">
                        {isNaN(blockData?._T118?.value) ? 'a2' : `a2 = ${blockData?._T118?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[21.75rem] left-[9.5rem] text-sm text-center">yn</Typography>
                    <Typography className="absolute w-full flex justify-center top-[21.75rem] left-[14.25rem] text-sm text-center">y'n</Typography>

                    <Typography className="absolute w-full flex justify-center top-[19.5rem] left-[9.25rem] text-sm text-center">y1</Typography>
                    <Typography className="absolute w-full flex justify-center top-[19.5rem] left-[14.5rem] text-sm text-center">y'1</Typography>

                    <Typography className="absolute w-full flex justify-center top-[42.75rem] left-[-15.25rem] text-sm text-center">
                        {isNaN(blockData?._T104?.value) ? 'w' : `w = ${blockData?._T104?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[40.75rem] left-[-14.5rem] text-sm text-center">
                        {isNaN(blockData?._iL3?.value) ? 'L3' : `L3 = ${blockData?._iL3?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-start top-[34.25rem] pl-[26rem] text-sm text-center">
                        {isNaN(blockData?._T104?.value) ? 't2' : `t2 = ${blockData?._T100?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-start top-[35.5rem] left-[26rem] text-sm text-center">
                        {isNaN(blockData?._T105?.value) ? 'R' : `R = ${blockData?._T105?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-start top-[37rem] left-[26rem] text-sm text-center">
                        {isNaN(blockData?._T104?.value) ? 'h3' : `h3 = ${blockData?._T103?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-start top-[34.25rem] left-[35rem] text-sm text-center">
                        {isNaN(blockData?._T104?.value) ? 't2' : `t2 = ${blockData?._T100?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-start top-[35.5rem] left-[35rem] text-sm text-center">
                        {isNaN(blockData?._T105?.value) ? 'R' : `R = ${blockData?._T105?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-start top-[37rem] left-[35rem] text-sm text-center">
                        {isNaN(blockData?._T104?.value) ? 'h3' : `h3 = ${blockData?._T103?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[41.5rem] left-[5.5rem] text-sm text-center">
                        {isNaN(blockData?._T102?.value) ? 't3' : `t3 = ${blockData?._T102?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[41.5rem] left-[12.5rem] text-sm text-center">
                        {isNaN(blockData?._T102?.value) ? 't3' : `t3 = ${blockData?._T102?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[30.25rem] left-[12rem] text-sm text-center">
                        {isNaN(blockData?._T101?.value) ? 'L2' : `L2 = ${blockData?._T101?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[26.25rem] left-[-5.75rem] text-sm text-center">主桁ウェブ</Typography>
                    <Typography className="absolute w-full flex justify-center top-[28.25rem] left-[-6rem] text-sm text-center">主桁補強材</Typography>
                    <Typography className="absolute w-full flex justify-center top-[40.25rem] left-[-22.75rem] text-sm text-center">補強リブ</Typography>
                    <Typography className="absolute w-full flex justify-center top-[40.25rem] left-[-6.5rem] text-sm text-center">トッププレート</Typography>
                    <Typography className="absolute w-full flex justify-center top-[38.25rem] left-[-21.5rem] text-sm text-center">ベースプレート</Typography>

                    <Typography className="absolute w-full flex justify-center top-[30.25rem] left-[23rem] text-sm text-center">ベースプレート</Typography>
                    <Typography className="absolute w-full flex justify-center top-[32.25rem] left-[24.25rem] text-sm text-center">補強リブ</Typography>
                    <Typography className="absolute w-full flex justify-center top-[40.25rem] left-[24rem] text-sm text-center">有効のど厚</Typography>

                    <Typography className="absolute w-full flex justify-center top-[8.25rem] left-[-20rem] text-sm text-center">HR</Typography>
                    <Typography className="absolute w-full flex justify-center top-[10.75rem] left-[-19.5rem] text-sm text-center">T</Typography>
                    <Typography className="absolute w-full flex justify-center top-[10.75rem] left-[-13rem] text-sm text-center">VR</Typography>
                    <Typography className="absolute w-full flex justify-center top-[9.25rem] left-[-17.75rem] text-sm text-center">θ</Typography>

                    <Typography className="absolute w-full flex justify-center top-[8.25rem] left-[6rem] text-sm text-center">HR</Typography>
                    <Typography className="absolute w-full flex justify-center top-[10.75rem] left-[6.25rem] text-sm text-center">T</Typography>
                    <Typography className="absolute w-full flex justify-center top-[10.75rem] left-[13rem] text-sm text-center">VR</Typography>
                    <Typography className="absolute w-full flex justify-center top-[9.25rem] left-[8.25rem] text-sm text-center">θ</Typography>
                </article>
                <InstructRow />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p97"
                    initValue={{ looking: blockData?._L97?.value, input: blockData?.p97?.value, calculate: blockData?._T97?.value }}
                    description="トッププレート長さ"
                    symbol={{ normalText: 'L1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p98"
                    initValue={{ looking: blockData?._L98?.value, input: blockData?.p98?.value, calculate: blockData?._T98?.value }}
                    description="トッププレート厚"
                    symbol={{ normalText: 't1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p99"
                    description="荷重の作用高さ"
                    initValue={{ looking: blockData?._L99?.value, input: blockData?.p99?.value, calculate: blockData?._T99?.value }}
                    symbol={{ normalText: 'h1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p100"
                    description="ベースプレート厚"
                    initValue={{ looking: blockData?._L100?.value, input: blockData?.p100?.value, calculate: blockData?._T100?.value }}
                    symbol={{ normalText: 't2', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p101"
                    initValue={{ looking: blockData?._L101?.value, input: blockData?.p101?.value, calculate: blockData?._T101?.value }}
                    description="ベースプレート長さ"
                    symbol={{ normalText: 'L2', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p102"
                    initValue={{ looking: blockData?._L102?.value, input: blockData?.p102?.value, calculate: blockData?._T102?.value }}
                    description="補強リブ厚"
                    symbol={{ normalText: 't3', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p103"
                    initValue={{ looking: blockData?._L103?.value, input: blockData?.p103?.value, calculate: blockData?._T103?.value }}
                    description="補強リブ高さ"
                    symbol={{ normalText: 'h3', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p104"
                    initValue={{ looking: blockData?._L104?.value, input: blockData?.p104?.value, calculate: blockData?._T104?.value }}
                    description="補強リブ幅"
                    symbol={{ normalText: 'w', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p105"
                    initValue={{ looking: blockData?._L105?.value, input: blockData?.p105?.value, calculate: blockData?._T105?.value }}
                    description="スカーラップ"
                    symbol={{ normalText: 'R', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', paddingBottom: 2 }} />
                <InstructRow title="ＨＴＢの計算" />

                <RowTextThreeInput
                    blockNumber="block007"
                    name="p111"
                    initValue={{ looking: blockData?._L111?.value, input: blockData?.p111?.value, calculate: blockData?._T111?.value }}
                    description="荷重の作用高さ"
                    symbol={{ normalText: 'h2', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p112"
                    initValue={{ looking: blockData?._L112?.value, input: blockData?.p112?.value, calculate: blockData?._T112?.value }}
                    description="ボルト中心間隔"
                    symbol={{ normalText: 'b1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p113"
                    initValue={{ looking: blockData?._L113?.value, input: blockData?.p113?.value, calculate: blockData?._T113?.value }}
                    description="ボルト本数"
                    symbol={{ normalText: 'n', mathSymbol: '=' }}
                    unit={UNIT.hon}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p114"
                    initValue={{ looking: blockData?._L114?.value, input: blockData?.p114?.value, calculate: blockData?._T114?.value }}
                    description="1列のボルト本数"
                    symbol={{ normalText: 'n1', mathSymbol: '=' }}
                    unit={UNIT.hon}
                />
                <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', paddingBottom: 2 }} />

                <RowTextThreeInput
                    blockNumber="block007"
                    name="p116"
                    initValue={{ looking: blockData?._L116?.value, input: blockData?.p116?.value, calculate: blockData?._T116?.value }}
                    description="偏心量"
                    hidden="input"
                    symbol={{ normalText: 'e', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p117"
                    initValue={{ looking: blockData?._L117?.value, input: blockData?.p117?.value, calculate: blockData?._T117?.value }}
                    description="ボルトの縁端距離 (横)"
                    symbol={{ normalText: 'a1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p118"
                    initValue={{ looking: blockData?._L118?.value, input: blockData?.p118?.value, calculate: blockData?._T118?.value }}
                    description="ボルトの縁端距離 (縦)"
                    symbol={{ normalText: 'a2', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p119"
                    initValue={{ looking: blockData?._L119?.value, input: blockData?.p119?.value, calculate: blockData?._T119?.value }}
                    description="ボルトからリブ縁までの距離"
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p120"
                    initValue={{ looking: blockData?._L120?.value, input: blockData?.p120?.value, calculate: blockData?._T120?.value }}
                    description="主桁下フランジ材厚"
                    hidden="looking"
                    symbol={{ normalText: "t'", mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
            </Stack>
        </Card>
    );
};

export default Block0301;
